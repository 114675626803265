import { Roles, User } from 'src/types/user';

export const addLeadingZeros = (num: any, totalLength: number) => {
  return String(num).padStart(totalLength, '0');
};

export const isSuperAdmin = (user: User) =>
  !!user?.roles?.find(r => r === Roles.superAdmin);

export const isTasFiler = (user: User) =>
  !!user?.roles?.find(r => r === Roles.taxFiler);

export const formatQuarterly = (quarter: number) => {
  switch (quarter) {
    case 1:
      return quarter + 'st';
    case 2:
      return quarter + 'nd';
    case 3:
      return quarter + 'rd';
    case 4:
      return quarter + 'th';
    default:
      break;
  }
};
