import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Container,
  Grid,
  Typography,
  } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { Theme } from 'src/theme';
import ReactPlayer from 'react-player/lazy'

interface FeatureVideoProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    paddingTop: 50,
    paddingBottom: 128,
    [theme.breakpoints.down('sm')]: { paddingBottom: 80 }
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  title: {
    color: '#183B56',
    fontSize: 36,
    [theme.breakpoints.down('sm')]: { 
      fontSize: 30
    }
  },
  subTitle: { 
    color: '#5A7184', 
    fontWeight: 300, 
    alignSelf: "center",
    width:650,
    [theme.breakpoints.down('sm')]: { 
      padding: 10,
      paddingTop: 0,
      width:320,
      fontSize: 16
    },
    [theme.breakpoints.down('xs')]: { 
      width:20,
      fontSize: 16
    }
  },
  label: {
    color: '#183B56',
  },
  description: {
    color: '#5A7184',
    fontWeight: 300,
    width: 280,
    justifyContent: 'center',
  },
  icon: {
    height: 69.79,
    width: 64,
    // marginRight: theme.spacing(2)
    marginBottom: 30
  },
}));

const FeatureVideo: FC<FeatureVideoProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          align="center"
          className={classes.title}
        >
          How does the Mobile App work?
        </Typography>
        <Box display={'flex'} flexDirection={'column'} mb={8}>
          <Typography
            mt={3}
            component="p"
            align="center"
            className={classes.subTitle}
          >
            Our goal is to minimize the manual work you need to do to compute and file your taxes. The app is designed to automate the computation and filing of your income tax, all you need to do is to simply add your sales, pay your tax due, and submit your tax for processing.
          </Typography>
        </Box>
        <Box style={{ justifyContent: 'center', display: 'flex' }}>
          <ReactPlayer url='https://www.youtube.com/watch?v=j8TYzBKee7g' width={'90%'} height={600}/>
        </Box>
        
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex" flexDirection={'column'} alignItems={'center'}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                    className={classes.label}
                  >
                    Step 1: Create Account
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    align='center'
                    className={classes.description}
                  >
                    Check your BIR 2303 form to register your tax account/s on the app.
                  </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex" flexDirection={'column'} alignItems={'center'}>
                {/* <Box ml={2}> */}
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                    className={classes.label}
                  >
                    Step 2: Add Sales
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.description}
                    align='center'
                  >
                    You may also add sales with Creditable Withholding Tax or CWT by referring to your signed 2307 forms.
                  </Typography>
                </Box>
              {/* </Box> */}
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex" flexDirection={'column'} alignItems={'center'}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                    className={classes.label}
                  >
                    Step 3: Submit Tax
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                    align='center'
                    className={classes.description}
                  >
                    Simply upload your tax payment proof on the app and click Submit Tax. Taxmate will do the rest.
                  </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

FeatureVideo.propTypes = {
  className: PropTypes.string
};

export default FeatureVideo;
