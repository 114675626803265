import React, { Suspense } from 'react';
import type { FC } from 'react';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Hero from './Hero';
import LoadingScreen from 'src/components/LoadingScreen';
import FeatureVideo from './FeatureVideo';

const Features = React.lazy(() => import('./Features'));
const Launch = React.lazy(() => import('./Launch'));
const Footer = React.lazy(() => import('./Footer'));

const useStyles = makeStyles(() => ({
  root: {}
}));

const HomeView: FC = () => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Home"
    >
      <Suspense fallback={<LoadingScreen />}>
        <Hero />
        <Features />
        <FeatureVideo />
        <Launch />
        <Footer />
      </Suspense>
    </Page>
  );
};

export default HomeView;
