import React, { createContext, useContext, useState } from 'react';

export interface TaxContextState {
  totalCWT: number;
  setTotalCWT?: any;
}

export const TaxContext = createContext<TaxContextState>({
  totalCWT: 0
});

export function useTaxContext() {
  return useContext(TaxContext);
}

export function TaxProvider({ children }: any) {
  const [totalCWT, setTotalCWT] = useState<number>(0);

  return (
    <TaxContext.Provider value={{ totalCWT, setTotalCWT }}>
      {children}
    </TaxContext.Provider>
  );
}
