import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import HomeView from 'src/views/home/HomeView';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import SuperAdminGuard from './guards/SuperAdminGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => {
              const Child = () => (
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              );
              if (!!route?.guard?.length) {
                return route?.guard?.map((Guard: any) => (
                  <Guard>
                    <Child />
                  </Guard>
                ));
              } else {
                return (
                  <Fragment>
                    <Child />
                  </Fragment>
                );
              }
            }}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    path: '/help-center',
    component: lazy(() => import('src/views/help-center'))
  },
  {
    exact: true,
    path: '/privacy-policy',
    component: lazy(() => import('src/views/privacy-policy'))
  },
  {
    exact: true,
    path: '/request-account-deletion',
    component: lazy(() => import('src/views/request-account-deletion'))
  },
  {
    exact: true,
    path: '/certificate-of-accreditation',
    component: lazy(() => import('src/views/gov-info'))
  },
  {
    exact: true,
    path: '/terms-and-conditions',
    component: lazy(() => import('src/views/auth/TermsAndConditions'))
  },
  {
    exact: true,
    path: '/terms-of-use',
    component: lazy(() => import('src/views/auth/EULA'))
  },
  {
    exact: true,
    path: '/user/recurring_payment/success',
    component: lazy(() => import('src/views/account/SuccessPage'))
  },
  {
    exact: true,
    path: '/user/recurring_payment/failed',
    component: lazy(() => import('src/views/account/FailedPage'))
  },
  {
    exact: true,
    guard: [GuestGuard],
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: [AuthGuard],
    path: '/logout',
    component: lazy(() => import('src/views/auth/LoginView/LogoutView'))
  },

  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    exact: true,
    guard: [GuestGuard],
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app',
    guard: [AuthGuard],
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/app/kanban',
        component: lazy(() => import('src/views/kanban/KanbanView'))
      },
      {
        exact: true,
        path: '/app/mail',
        component: () => <Redirect to="/app/mail/all" />
      },
      {
        guard: [SuperAdminGuard],
        exact: true,
        path: '/app/management/tax-filer',
        component: lazy(() => import('src/views/tax-filer/TaxFilerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers',
        component: lazy(() => import('src/views/customer/CustomerListView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId',
        component: lazy(() => import('src/views/customer/CustomerDetailsView'))
      },
      {
        exact: true,
        path: '/app/management/customers/:customerId/edit',
        component: lazy(() => import('src/views/customer/CustomerEditView'))
      },
      {
        exact: true,
        path: '/app/management/cor',
        component: lazy(() => import('src/views/cor/OrderListView'))
      },
      {
        exact: true,
        path: '/app/management/cor/:tin',
        component: lazy(() => import('src/views/cor/CorDetailsView'))
      },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/customers" />
      },
      {
        exact: true,
        path: '/app/projects',
        component: () => <Redirect to="/app/projects/browse" />
      },
      {
        exact: true,
        path: '/app/reports',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/reports/dashboard" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: HomeView
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
