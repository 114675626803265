import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';

const rootReducer = combineReducers({
  form: formReducer,
  kanban: kanbanReducer
});

export default rootReducer;
