import React from 'react';
import type { FC } from 'react';

interface LogoProps {
  [key: string]: any;
}

const Logo: FC<LogoProps> = (props) => {
  const src = props?.dark ? '/static/logo_dark.png' : '/static/logo3.png'
  return (
    <img
      alt="Logo"
      src={src}
      height={27}
      {...props}
    />
  );
}

export default Logo;
