/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  UserCheck as UserCheckIcon,
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { Roles, User } from 'src/types/user';
import { isSuperAdmin, isTasFiler } from 'src/common/utils';
import FullNameModal from 'src/components/FullNameModal';

interface NavBarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

interface Item {
  href?: string;
  icon?: ReactNode;
  info?: ReactNode;
  items?: Item[];
  title: string;
  roles?: Roles[];
}

interface Section {
  items: Item[];
  subheader?: string;
  roles?: Roles[];
}

const sections: Section[] = [
  // {
  //   items: [
  //     // {
  //     //   title: 'Dashboard',
  //     //   icon: PieChartIcon,
  //     //   href: '/app/reports/dashboard'
  //     // },
  //     {
  //       title: 'Dashboard',
  //       icon: BarChartIcon,
  //       href: '/app/reports/dashboard'
  //     },
  //   ]
  // },
  {
    // subheader: 'Management',
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/reports/dashboard',
        roles: [Roles.superAdmin],
      },
      {
        title: 'Kanban',
        href: '/app/kanban',
        icon: TrelloIcon,
        roles: [Roles.superAdmin, Roles.taxFiler],
      },
      {
        title: 'Clients',
        icon: UsersIcon,
        href: '/app/management/customers',
        roles: [Roles.superAdmin, Roles.taxFiler],
        // items: [
        //   {
        //     title: 'List Customers',
        //     href: '/app/management/customers'
        //   },
        //   {
        //     title: 'View Customer',
        //     href: '/app/management/customers/1'
        //   },
        //   {
        //     title: 'Edit Customer',
        //     href: '/app/management/customers/1/edit'
        //   }
        // ]
      },
      
      {
        title: 'Tax Filer',
        icon: UserCheckIcon,
        href: '/app/management/tax-filer',
        roles: [Roles.superAdmin],
      },
      // {
      //   title: 'Mail',
      //   href: '/app/mail',
      //   icon: MailIcon
      // },
    ]
  },
];

function hasAccess(roles: string[], user: User) {
  return roles?.some(role => user?.roles?.find(r => r === role));
}

function renderNavItems({
  user,
  items,
  pathname,
  depth = 0
}: {
  user: User;
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => {
          if(item && user && hasAccess(item.roles, user)) {
            reduceChildRoutes({ user, acc, item, pathname, depth })
          }
          return acc;
        },
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  user,
  acc,
  pathname,
  item,
  depth
}: {
  user: User;
  acc: any[];
  pathname: string;
  item: Item;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          user,
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user, logout } = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      {isTasFiler(user) && !(user?.firstName || user?.lastname) && <FullNameModal email={user.email} uid={user?.uid || user.id} logout={logout}/> }
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {isSuperAdmin(user) ? 'Admin' : isTasFiler(user) ? 'Tax Filer' : ''}
              {/* Your tier: */}
              {' '}
              {/* <Link
                component={RouterLink}
                to="/pricing"
              >
                {user.tier}
              </Link> */}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                user,
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        {/* <Divider />
        <Box p={2}>
          <Box
            p={2}
            borderRadius={1}
            bgcolor="background.dark"
          >
            <Typography
              variant="h6"
              color="textPrimary"
            >
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return <>
    <Hidden lgUp>
      <Drawer
        anchor="left"
        classes={{ paper: classes.mobileDrawer }}
        onClose={onMobileClose}
        open={openMobile}
        variant="temporary"
      >
        {content}
      </Drawer>
    </Hidden>
    <Hidden lgDown>
      <Drawer
        anchor="left"
        classes={{ paper: classes.desktopDrawer }}
        open
        variant="persistent"
      >
        {content}
      </Drawer>
    </Hidden>
  </>;
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
