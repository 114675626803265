export enum Roles {
  superAdmin = 'SuperAdmin',
  taxFiler = 'TaxFiler'
}

export interface User {
  id: string;
  avatar: string;
  email: string;
  name: string;
  firstName?: string;
  lastname?: string;
  roles?: Roles[];
  [key: string]: any;
}
