import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { Roles } from 'src/types/user';

interface ClientGuardProps {
  children?: ReactNode;
}

const SuperAdminGuard: FC<ClientGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (!!user && !!user?.roles && !!user?.roles?.find(r => r === Roles.superAdmin)) {
    return (
      <>
        {children}
      </>
    )
  } else {
    return null
  }
};

SuperAdminGuard.propTypes = {
  children: PropTypes.node
};

export default SuperAdminGuard;
