import React from 'react';
import type { FC } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  } from '@mui/material';
import { makeStyles } from '@mui/styles';
import type { Theme } from 'src/theme';
import Logo from 'src/components/Logo';

interface HeroProps {
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#F2F2F2',
    paddingTop: 50,
    [theme.breakpoints.down('sm')]: { paddingTop: 30 },
  },
  storeIcon: {
    height: 54,
    width: 171,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: { 
      height: 47,
      width: 150,
     },
  },
  image: {
    perspectiveOrigin: 'left center',
    transformStyle: 'preserve-3d',
    perspective: 1500,
    '& > img': {
      maxWidth: '90%',
      height: 'auto',
      transform: 'rotateY(-35deg) rotateX(15deg)',
      backfaceVisibility: 'hidden',
      boxShadow: theme.shadows[16]
    }
  },
  shape: {
    position: 'absolute',
    top: 0,
    left: 0,
    '& > img': {
      maxWidth: '90%',
      height: 'auto'
    }
  },
  header: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: { 
      alignItems: 'flex-start',
      flexDirection: 'column',
      justifyContent: 'center',
     },
  },
  headerLinks: {
    marginRight: 160,
    [theme.breakpoints.down('sm')]: { 
      marginTop: 32,
      marginRight: 0
    },
  },
  title: {
    fontSize: 56,
    [theme.breakpoints.down('xl')]: { fontSize: 50 },
    [theme.breakpoints.down('lg')]: { fontSize: 48 },
    [theme.breakpoints.down('md')]: { fontSize: 48 },
    [theme.breakpoints.down('sm')]: { 
      fontSize: 36,
      marginBottom: 7
     },
    fontWeight: 'bold',
    letterSpacing: 0.2,
    color: '#10243E'
  },
  subTitle: {
    color: '#183B56', 
    fontSize: 20, 
    [theme.breakpoints.down('lg')]: { fontSize: 18 },
    marginRight: 30, 
    fontWeight: 300
  },
  ovalBox: {
    [theme.breakpoints.up('xl')]: { height: 800 },
    [theme.breakpoints.down('xl')]: { height: 700 },
    [theme.breakpoints.down('md')]: { height: 400 },
    overflow: 'hidden'
  },
  oval: {
    [theme.breakpoints.up('xl')]: {
      height: 1100,
      width: 1100,
    },
    [theme.breakpoints.down('xl')]: {
      height: 900,
      width: 900,
    },
    [theme.breakpoints.down('md')]: { 
      height: 700, 
      width: 700, 
      right: -300
    },
    [theme.breakpoints.down('sm')]: { 
      height: 500, 
      width: 500, 
      right: -100
    },
    backgroundColor: '#EFD800',
    borderRadius: '50%',
    position: 'relative'

  },
  iPhoneImg: {
    top: -550,
    right: 60,
    height: 782,
    width: 598,
    [theme.breakpoints.down('lg')]: { 
      right: -30,
      height: 744,
      width: 569,
    },
    [theme.breakpoints.down('md')]: { 
      top: -450,
      height: 600, 
      width: 459, 
      right: -30
    },
    [theme.breakpoints.down('sm')]: { 
      top: -380,
      height: 500, 
      width: 383, 
      right: -20
    },
    position: 'absolute'
  },
  bubble: {
    top: 5,
    [theme.breakpoints.up('xl')]: { marginTop: -200 },
    [theme.breakpoints.up('lg')]: { marginTop: -150 },
    [theme.breakpoints.down('lg')]: { marginTop: -100 },
    [theme.breakpoints.down('md')]: { marginTop: -50 },
    [theme.breakpoints.down('sm')]: { marginTop: -30 },
    position: 'relative',
  }
}));

const Hero: FC<HeroProps> = ({ className, ...rest }) => {
  const classes = useStyles();
  const isTabletSize = useMediaQuery((theme: any) => theme.breakpoints.down('lg'));
  const isPhoneSize = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));
  const paddingLeft = isPhoneSize ? 25 : isTabletSize ? 50 : 150;

  const onNavigateToPlaystore = () => window.open('https://play.google.com/store/apps/details?id=ph.titosolutions.taxmate', '_blank').focus();
  const onNavigateToAppstore = () => window.open('https://apps.apple.com/ph/app/taxmate/id6444185619', '_blank').focus();
  const onNavigateToHelpCenter = () => window.open('https://taxmate.ph/help-center', '_blank').focus();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <>
        <Box className={classes.header} style={{ paddingLeft }}>
          <Logo width={196} height={35}/>
          <Box className={classes.headerLinks}>
            <Button style={{ color: 'black', textTransform: 'capitalize'}}>Home</Button>
            <Button style={{ color: 'black', marginLeft: 6, textTransform: 'capitalize' }} onClick={onNavigateToHelpCenter}>Help Center</Button>
          </Box>
        </Box>
        <Grid container >
          <Grid
            item
            xs={12}
            md={6}
            style={{ paddingLeft }}
          >
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              mt={{ sm: 5, md: 15 }}
            >
              <Typography className={classes.title}>
                Enjoy Hassle-free
              </Typography>
              <Typography className={classes.title} style={{ marginTop: -20 }}>
                BIR Tax Compliance
              </Typography>
              <Box mt={3} mb={3}>
                <Typography className={classes.subTitle}>
                  Taxmate is your buddy for tax compliance. File your taxes through your phone -- hassle-free, convenient, and fully compliant. 
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography
                  style={{ color: '#B3BAC5', fontSize: 16, fontWeight: 300}}
                >
                  <b style={{ color: '#5A7184'}}>Download now</b> at the following stores:
                </Typography>
              </Box>
              <Box mt={2}>
                <img
                  alt="App Store"
                  className={classes.storeIcon}
                  src="/static/images/buttons/app-store@2x.png"
                  onClick={onNavigateToAppstore}
                />
                <img
                  alt="Play Store"
                  className={classes.storeIcon}
                  src="/static/images/buttons/play-store@2x.png"
                  onClick={onNavigateToPlaystore}
                />
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
          >
            <Box className={classes.ovalBox}>
              <Box className={classes.oval} />
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.bubble}>
          <svg viewBox="0 0 2160 263" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path id="Wave" fill-rule="evenodd" clip-rule="evenodd" d="M2160 262.5H0V0C360 80 720 120 1080 120C1440 120 1800 80 2160 0V262.5Z" fill="white"/>
          </svg>
          <img
            alt="Phone"
            className={classes.iPhoneImg}
            src="/static/images/taxmate-iphone.png"
          />
        </Box>
       
      </>
    </div>
  );
};

Hero.propTypes = {
  className: PropTypes.string
};

export default Hero;
